var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.getSelectedTransaction)?_c('div',{staticClass:"widgetContainer widgetContainer--scrollable  full-width transactionDetails"},[_c('div',{staticClass:"navigationHeader"},[_c('div',{staticClass:"navigationHeader__inner"},[_c('p',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t('transaction_details'))+" ")]),_c('span',{staticClass:"el-icon-close",on:{"click":_vm.drawerClose}})])]),_c('div',{staticClass:"widgetContainer__body"},[_c('div',{staticClass:"listWithAmount"},[_c('ul',{staticClass:"summary-box"},[_c('li',{staticClass:"cursor-auto"},[_c('div',{staticClass:"details trans-details"},[_c('p',{class:'amount ' +
                  (_vm.getSelectedTransaction.status == 'pending'
                    ? 'pending'
                    : _vm.getSelectedTransaction.txnType === 'debit'
                      ? 'minus'
                      : 'plus')},[_vm._v(" "+_vm._s(_vm._f("transactionAmount")(_vm.getSelectedTransaction.amount))+" ")]),_c('p',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.getSelectedTransaction.title)+" ")]),(_vm.getSelectedTransaction.status == 'pending')?_c('p',{staticClass:"value"},[_vm._v(" "+_vm._s(_vm.$t('RCD_pending'))+" ")]):_c('p',{staticClass:"value"},[_vm._v(" "+_vm._s(_vm._f("date")(_vm.getSelectedTransaction.txnDate,"MMM d, yyyy 'at' h:mm aa"))+" ")])]),_c('el-tag',{class:'el-tag--blue transaction-type type-' + _vm.transferTypeTitle},[_vm._v(" "+_vm._s(_vm.transferTypeTitle)+" ")])],1)])]),_c('div',{staticClass:"listSimple transactionDetails__other-details"},[_c('h4',{staticClass:"header-2"},[_vm._v(" "+_vm._s(_vm.$t('other_details'))+" ")]),_c('ul',[(_vm.data.source && false)?_c('li',[_c('p',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t('source'))+" ")]),_c('p',{staticClass:"value"},[_vm._v(" "+_vm._s(_vm.data.source)+" ")])]):_vm._e(),(_vm.data.paidTo)?_c('li',[_c('p',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t('merchant'))+" ")]),_c('p',{staticClass:"value"},[_vm._v(" "+_vm._s(_vm.data.paidTo)+" ")])]):_vm._e(),(_vm.data.transactionId && false)?_c('li',[_c('p',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t('transactionId'))+" ")]),_c('p',{staticClass:"value"},[_vm._v(" "+_vm._s(_vm.data.transactionId)+" ")])]):_vm._e(),(_vm.data.description)?_c('li',[_c('p',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t('description'))+" ")]),_c('p',{staticClass:"value"},[_vm._v(" "+_vm._s(_vm.data.description)+" ")])]):_vm._e()])]),_c('div',{staticClass:"listWithActions transactionDetails__actions"},[_c('h4',{staticClass:"header-2"},[_vm._v(" "+_vm._s(_vm.$t('actions'))+" ")]),_c('ul',[_c('li',{on:{"click":function($event){return _vm.getData(true)}}},[_vm._v(" "+_vm._s(_vm.$t('viewPDF'))+" ")]),_c('li',{on:{"click":_vm.openHelp}},[_vm._v(" "+_vm._s(_vm.$t('reportProblem'))+" ")])])]),(
        _vm.getSelectedTransaction.transferType === 'card' &&
          _vm.getSelectedTransaction.card &&
          _vm.getSelectedTransaction.card.merchant
      )?_c('div',{staticClass:"listSimple"},[_c('h4',{staticClass:"header-2"},[_vm._v(" "+_vm._s(_vm.$t('location'))+" ")]),_c('ul',{staticClass:"card-with-map"},[_c('div',{staticClass:"map"},[(_vm.lat && _vm.lng)?_c('GmapMap',{staticStyle:{"width":"100%","height":"200px"},attrs:{"center":{ lat: _vm.lat, lng: _vm.lng },"zoom":7,"map-type-id":"terrain"}},[_c('GmapMarker',{attrs:{"position":{ lat: _vm.lat, lng: _vm.lng },"clickable":true,"draggable":false}})],1):_vm._e()],1),_c('li',[_c('p',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t('address'))+" ")]),_c('p',{staticClass:"value"},[_vm._v(" "+_vm._s(_vm.address)+" ")])]),_c('li',[_c('p',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t('contact_country_title'))+" ")]),_c('p',{staticClass:"value"},[_vm._v(" "+_vm._s(_vm.getSelectedTransaction.card.merchant.merchantCountry)+" ")])])])]):_vm._e()])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }